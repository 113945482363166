<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6">
        <h1 class="mb-3 text-center">{{editing ? 'Edit' : 'New'}} Site</h1>

        <v-card class="pa-4">
          <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
            <v-text-field
              v-model="form.name"
              :rules="rules.name"
              label="Site Name"
              name="name"
            ></v-text-field>

            <v-text-field
              v-model="form.baseUrl"
              :rules="rules.baseUrl"
              label="Base URL"
              name="base-url"
            ></v-text-field>

            <v-alert
              v-model="showError"
              dense
              outlined
              dismissible
              type="error"
            >{{errorMessage}}</v-alert>

            <v-btn
              type="submit"
              color="primary"
              class="mr-2"
              :disabled="!valid"
              :loading="submitting"
            >{{editing ? 'Update' : 'Create'}}</v-btn>

            <v-btn
              color="error"
              plain
              @click="$router.back()"
            >Cancel</v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    title() {
      return this.editing
        ? (this.site && `Edit ${this.site.name}`)
        : 'Create Site'
    },

    props: ['id'],

    data: function() {
      return ({
        editing: false,
        valid: false,
        form: {
          name: '',
          baseUrl: ''
        },
        rules: {
          name: [
            v => !!v || 'Site name is required'
          ],
          baseUrl: [
            v => !!v || 'Base URL is required',
            v => !(!v.startsWith('http://') && !v.startsWith('https://')) || 'Base URL must start with either http:// or https://',
            v => !(v.endsWith('/') && v.length > 'https://'.length) || 'Base URL must not end with a trailing slash'
          ]
        },
        showError: false,
        errorMessage: ''
      })
    },

    computed: {
      ...mapGetters({
        submitting: 'sites/submitting',
        updatedData: 'sites/updatedData',
        site: 'sites/site'
      })
    },

    watch: {
      updatedData: function() {
        if (this.updatedData !== null && !this.submitting) {
          // Reset the form
          this.$refs.form.reset()

          // Show success snackbar
          this.setSnackbar({
            message: `Site ${this.editing ? 'updated' : 'created'} successfully!`
          })

          // Route to new site
          this.$router.push({ name: 'site-details', params: { id: this.updatedData.id }})
        }
      }
    },

    created: async function() {
      // Check if we are editing or creating a site
      if (this.$route.params.id && this.site) {
        this.editing = true
        this.form = this.site
      }
    },

    beforeRouteEnter: async function(to, from, next) {
      await next(async vm => {
        if (to.params.id) {
          vm.setLoading(true)
          await vm.getSiteDetails(to.params.id)
          vm.setLoading(false)
        }
      })
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setSnackbar: 'setSnackbar',
        getSiteDetails: 'sites/getSiteDetails',
        storeSite: 'sites/storeSite'
      }),
      onSubmit: function() {
        // Parse the provided base URL
        this.form.baseUrl = this.form.baseUrl.replace(/\/$/, '')

        this.storeSite(this.form)
      }
    }
  }
</script>
